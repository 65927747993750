import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

// classroom item
import code from "../images/icons/code.svg"
import geometry from "../images/icons/geometry.svg"
import machine from "../images/icons/machine.svg"
import robot from "../images/icons/robot.svg"
import testTube from "../images/icons/test-tube.svg"
import threeDPrinter from "../images/icons/3d-printer.svg"
import developer from "../images/icons/developer.svg"
import origami from "../images/icons/origami.svg"

const ClassroomPage = () => (
  <Layout>
    <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />

    <div className="container classroom__container">
      <h1 className="container__header"> In the Classroom </h1>

      <div className="classroom__overview">
        <p> The Kasi Maths lessons currently run every Wednesday from 3:30pm to 5:00pm at the Rosa Parks Library in Soweto. We have a classroom of 20 students. Each term we focus on something new and exciting related to the STEM (Science, Technology, Engineering and Mathematics) fields. We then tie it all together with sel development skills. Below is an overview of what is covered during the year:
        </p>
      </div>

      <div className="classroom__item">
        <div className="classroom__item-image">
          <img src={geometry} alt="maths"/>
        </div>
        <div className="classroom__item-description">
          <div className="classroom__item-name">
            Classroom mathematics
          </div>
          <div className="classroom__item-text">
            <p>
              At the core of the program we focus on mathematics and improving the students logical and anlytic abilities. We go through whiteboard activities, and  use online programmes to explore diverse range of mathematical problem solving.
            </p>
            <p>
              We keep mathematics at the core of everything that we introduce to the learners at Kasi Maths. We show them that maths is incorporated in our daily life in many fun and interesting ways. In addition, all the activities that we introduce to the students incorporate the foundational aspects of mathematics, more specifically logical and analytical thinking and a way to problem solve.
            </p>
          </div>
        </div>
      </div>

      <div className="classroom__item">
        <div className="classroom__item-image">
          <img src={developer} alt="developer"/>
        </div>
        <div className="classroom__item-description">
          <div className="classroom__item-name">
            Coding + Electronics/Robotics
          </div>
          <div className="classroom__item-text">
            <p>
              It is important to us to help students to realise that nothing is impossible and that all the technology and gadgets that they use in their daily lives are built just using some logic on top of some hardware device. During these sessions we use real world examples of technology and try to re-build them in a simple form.
            </p>
            <p>
              As an example, the park distance sensor in a car is a really simple concept. We use sensors with the microbits together with some code to help students to understand the underlying mechanics. We start off the classes with basic scratch programming and then move on to show them how to connect hardware components with their code using <a href="https://microbit.org/">micro bits</a>.
            </p>
          </div>
        </div>
      </div>

      <div className="classroom__item">
        <div className="classroom__item-image">
          <img src={threeDPrinter} alt="threeDPrinter"/>
        </div>
        <div className="classroom__item-description">
          <div className="classroom__item-name">
            3D Printing
          </div>
          <div className="classroom__item-text">
            <p>
              The best way to help students to grasp geometry and its' underlying concepts is to show them how they would use it in the real world.
            </p>
            <p>
             During the 3D printing sessions, not only do the students have the opportunity to put their geometrical and mathematical knowledge to use, but they also get to build fun and creative 3D models that they eventually can print. We use the <a href="https://www.autodesk.com/education/free-software/autocad">AutoCad</a> software to show them design and modelling skills.
            </p>
          </div>
        </div>
      </div>

      <div className="classroom__item">
        <div className="classroom__item-image">
          <img src={testTube} alt="testTube"/>
        </div>
        <div className="classroom__item-description">
          <div className="classroom__item-name">
            Science and Chemistry
          </div>
          <div className="classroom__item-text">
            <p>
              Our future leaders in Science and Chemistry attend Kasi Maths sessions, and we believe that they should understand how chemicals are mixed together to make the daily essentials like soap, table salt and daily essentials. They have loads of fun whilst learning about the different chemical reactions. They exercise their mathematics knowledge by measuring and quantifying the underlying ingreadients, and thereafter following methods whilst realizing that diferent conditions allow for different outcomes.
            </p>
            <p>
              We have been using the <a href="https://nkazisciences.co.za/">Nkazi Sciences Kits</a> that allow our students to carry out a new experiment each week whilst learning about about fascinating concepts.
            </p>
          </div>
        </div>
      </div>

      <div className="classroom__item">
        <div className="classroom__item-image">
          <img src={origami} alt="origami"/>
        </div>
        <div className="classroom__item-description">
          <div className="classroom__item-name">
            Self Development
          </div>
          <div className="classroom__item-text">
              <p>
                We believe that science and technology is the future, but we also believe that our future leaders need to have skillsets beyond just mathematical. Hence, at the end of the year we focus on self development whereby we cover topics like: self-confidence, effective communication, collaboration, motivation, leadership, time management, goal setting and more. In addition, we expose students to an growth and entrrepreneural mindset that will help that once they step out into the working world.
              </p>
              <p>
                During these sessions, we cover lots of examples of successful relatable youth and adults in the community. These sessions are informal and exploratory - we allow the students to learn together and from each other, through discussion and mentorship.
              </p>
          </div>
        </div>
      </div>

    </div>
  </Layout>
)

export default ClassroomPage
